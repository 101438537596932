<template>
    <div style="text-align: center;margin-top: 10px;" class="cursor-pointer" @click="switch_mode()">
        <img v-if="mode == 'dark'" src="@/assets/images/icon/light-mode.svg" alt="" >
        <img v-else src="@/assets/images/icon/dark-mode.svg" alt="" >
    </div>
    <div class="body-main" v-if="!isLogin()">
        
        <div class="form-signin form-forgot main-overlay-np">
            <div class="title-form">     
                <h1><strong>Reset your password</strong></h1>
                <div class="text-center">Please enter and confirm your new password below and click continue.<br>You will then receive an email to confirm that your password has been successfully change. <br></div>
            </div>  
            <AlertBox v-if="message.text" :message="message.text" :variant="message.type"/> 
            <form @submit.prevent="submitForm" method="post">               
                <div class="mb-3 form-ct">
                    <input type="password" class="form-control" v-model="password" placeholder="New password" required>
                </div>
                <div class="mb-3 form-ct">
                    <input type="password" class="form-control" v-model="password_confirmation" placeholder="Confirm new password" required>
                </div>
            
                <div class="mt-2">
                                
                <div class="text-center">
                    <button class="btn btn-sea-blue btn-sm" type="submit">Submit </button>
                </div>   
                </div>
                <div v-if="load" class="overlay">
                    <div class="loadding_circle"></div>
                </div>
            </form>

        </div>
    </div>

                  
               
</template>

<script>
import { ref, reactive, onMounted, onUnmounted, watch  } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import { isLogin } from "@/utils";
import AlertBox from "@/components/elements/AlertBox.vue";
import useModeApp  from "@/hooks/useModeApp.js"
export default {  
     components: {
        AlertBox,
    },
    setup(){
         
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        if (isLogin()) {       
            router.push("/"); 
        }

        if(!route.query.token){
            router.push('/login');
        }
         
        const password = ref("")
        const password_confirmation = ref("")
        const load = ref(false)
        const message = reactive({
                text : "",
                type : "success"
        })
        const token = route.query.token
        console.log(token)

        const submitForm = () => {
            
            message.text = ""
            if(password.value !== password_confirmation.value){
                message.text = "The password confirmation does not match."
                message.type = "danger";
                return false
            }
            load.value = true
            
            store.dispatch("auth/reset_password", {password : password.value, password_confirmation : password_confirmation.value, token : token} ).then(
                (res) => {
                    console.log(res)
                    message.text = res.message;
                    message.type = "success"
                    password.value = ""
                    password_confirmation.value = ""
                    load.value = false
                },
                (error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        message.text = error.response.data.message;
                    }else{
                        message.text = error
                    }
                    password.value = ""
                    password_confirmation.value = ""
                    message.type = "danger"
                    load.value = false                          
                }
            );
        }
        //-------------------------- switch mode  ------------------------[start]  
        const { mode, switch_mode, addClassToBody } = useModeApp();      
        watch(mode, ()  => { 
            addClassToBody() 
        })
        onMounted(() => {
            addClassToBody()
        });
        onUnmounted(() => {
            document.body.classList.remove('light-mode', 'dark-mode')
        })
        //-------------------------- switch mode  ------------------------[end]

        return { password, password_confirmation, submitForm, load, message, switch_mode, isLogin, mode }
    } 
    
  
     
};
</script>

<style scoped>

.btn-sea-blue{padding: .25rem 1rem}

</style>