<template>
    <div :class="'alert alert-'+variant+' d-flex align-items-center'" role="alert">
        <div v-html="message"></div>
    </div> 
</template>

<script>
export default {
    props: [
        "message",
        "variant"
    ]
}
</script>